<template>
	<div class="nb-usdt-payment-container">
		<ModalContainer modalTitle="" :showClose="false" v-if="showConfirmDialog === true">
			<div class="nb-confirm-btn-container">
				<div class="nb-confirm-dialog-text">订单撤销后，转账记录将会丢失。确认撤销？</div>
				<div class="nb-confirm-btn-row">
					<div class="nb-confirm-btn" @click="onConfirmBtnClick">确认</div>
					<div class="nb-cancel-btn" @click="onCancelBtnClick">取消</div>
				</div>

			</div>
		</ModalContainer>
		<div class="nb-usdt-payment-wrapper">
			<div class="nb-usdt-payment-title">支付指南</div>
			<div class="nb-usdt-payment-guide">1、复制转账地址；</div>
			<div class="nb-usdt-payment-guide">2、打开您的数字钱包，向该地址转入{{}}USDT；</div>
			<div class="nb-usdt-payment-guide">3、转账完成后点击“我已支付”完成验证。</div>
			<div class="nb-usdt-payment-guide">4、区块确认需要几分钟，您可以多次点击“我已支付”直到验证通过。</div>
			<div class="nb-usdt-trans-address-container">
				<div class="nb-usdt-trans-address-title">
					网络类型： TRC20（波场链）
				</div>
				<div class="nb-usdt-trans-address-title-container">
					<div class="nb-usdt-trans-address-label">转账地址：</div>
					<div class="nb-usdt-trans-address-copy-btn" @click="copyTrcAddress">复制地址</div>
				</div>
				<div class="nb-usdt-trans-address-title">
					{{ this.trcAddress }}
				</div>
			</div>
			<div class="nb-usdt-trans-address-container">
				<div class="nb-usdt-trans-address-title">
					网络类型： BEP20（币安智能链）
				</div>
				<div class="nb-usdt-trans-address-title-container">
					<div class="nb-usdt-trans-address-label">转账地址：</div>
					<div class="nb-usdt-trans-address-copy-btn" @click="copyBscAddress">复制地址</div>
				</div>
				<div class="nb-usdt-trans-address-title">
					{{ this.bscAddress }}
				</div>
			</div>
			<div class="nb-usdt-payment-btn-container">
				<div class="nb-usdt-payment-cancel-btn" @click="onCancelOrder">取消订单</div>
				<div class="nb-usdt-payment-confirm-btn" @click="onPaymentComplete">我已支付</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	getTransactionById,
	showToast,
	showLoading,
	hideLoading,
	verifyTransaction
} from "@/utils/methods";
import copy from 'copy-to-clipboard';
import ModalContainer from "@/components/ModalContainer";
export default {
	name: "UsdtPaymentPage",
	components: {
		ModalContainer
	},
	async mounted() {
		const id = this.$route.query.id;
		this.transactionId = id
		showLoading()
		const result = await getTransactionById(id)
		hideLoading()
		if (result.code === 200) {
			this.bscAddress = result.msg?.bscAddress
			this.trcAddress = result.msg?.trcAddress
		} else {
			showToast('获取支付信息失败')
		}
	},
	data() {
		return {
			transactionId: null,
			bscAddress: null,
			trcAddress: null,
			showConfirmDialog: false
		}
	},
	methods: {
		copyBscAddress() {
			const copyResult = copy(this.bscAddress);
			if (copyResult ===true) {
				showToast('复制成功');
			} else {

				showToast('复制失败');
			}
		},
		copyTrcAddress() {
			const copyResult = copy(this.trcAddress);
			if (copyResult ===true) {
				showToast('复制成功');
			} else {

				showToast('复制失败');
			}
		},
		onCancelOrder() {
			this.showConfirmDialog = true
		},
		async onPaymentComplete() {
			if (!this.transactionId) {
				showToast('订单信息错误')
				return
			}
			showLoading()
			const verifyResult = await verifyTransaction(this.transactionId)
			hideLoading()

			if (verifyResult.code === 201 || verifyResult.code === 202 || verifyResult.code === 203) {
				showToast('参数错误，请重新生成订单。')
				return
			}
			if (verifyResult.code === 206) {
				showToast('未发现转账记录。')
				return
			}
			if (verifyResult.code === 207) {
				showToast('转账金额不足。')
				return
			}

			if (verifyResult.code === 200) {
				showToast('支付成功')
				this.$router.replace({ path: '/' });
			}
		},
		onConfirmBtnClick() {
			this.showConfirmDialog = false
			this.$router.replace({ path: '/' });
		},
		onCancelBtnClick() {
			this.showConfirmDialog = false
		}
	}
}
</script>

<style scoped>
.nb-usdt-payment-container {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	justify-content: center;
}

.nb-usdt-payment-wrapper {
	width: 60em;
	padding: 1em;
	display: flex;
	flex-direction: column;
	align-items: start;
	box-sizing: border-box;
}

.nb-usdt-payment-title {
	color: #E6D5B8;
	font-size: 2.4em;
	width: 100%;
	text-align: center;
	margin-bottom: 1em;
}

.nb-usdt-payment-guide {
	color: #E6D5B8;
	font-size: 2em;
	margin-bottom: 0.4em;
	width: 100%;
	text-align: start;
}

.nb-usdt-trans-address-container {
	display: flex;
	flex-direction: column;
	border: 1px solid #ff8900;
	border-radius: 0.4em;
	width: 100%;
	padding: 1em;
	box-sizing: border-box;
	margin-top: 1em;
}

.nb-usdt-trans-address-title {
	color: #ff8900;
	font-size: 2em;
	margin-bottom: 0.4em;
	width: 100%;
	text-align: start;
	word-wrap: break-word;
}

.nb-usdt-trans-address-label {
	color: #ff8900;
	font-size: 2em;
	text-align: start;
	flex: 1;
}

.nb-usdt-trans-address-title-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	color: #ff8900;
	margin-bottom: 0.8em;
}

.nb-usdt-trans-address-copy-btn {
	width: fit-content;
	font-size: 1.8em;
	background-color: #FF8900;
	color: #1f1f35;
	text-wrap: nowrap;
	padding: 0.1em 0.4em;
	border-radius: 0.2em;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer
}

.nb-usdt-payment-cancel-btn{
	background-color: #1f1f35;
	color: #ff8900;
	font-size: 1.6em;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0.2em;
	cursor: pointer;
	padding: 0.4em 1em;
	border: 1px solid #ff8900;
}

.nb-usdt-payment-confirm-btn{
	background-color: #ff8900;
	color: #1f1f35;
	font-size: 1.6em;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0.2em;
	cursor: pointer;
	padding: 0.4em 1em;
	border: 1px solid #ff8900;
}

.nb-usdt-payment-btn-container{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-top: 1em;
}

@media (max-width: 900px) {
	.nb-usdt-payment-wrapper {
		width: 100% !important;
	}
}
</style>