<template>
	<div class="nb-register-account-container">
		<div class="nb-register-account-tab-container" v-if="this.regOrLogin === 'BOTH'">
			<div class="nb-register-account-tab" :class="{'active-tab': this.currentSelectedTab === 'LOGIN'}"
			     @click="onLoginClicked">登录
			</div>
			<div class="nb-register-account-tab" :class="{'active-tab': this.currentSelectedTab === 'REGISTER'}"
			     @click="onRegisterClicked">注册
			</div>
		</div>
		<div class="nb-register-form-container" v-if="this.currentSelectedTab === 'REGISTER'">
			<div class="nb-register-account-label">邮箱</div>
			<div class='nb-login-email-field-container'>
				<input class="nb-login-input-email" type="email" placeholder="请在此输入您的邮箱" v-model="this.email"/>
				<div class="nb-login-send-vcode-btn disable-select" @click="onSendVcode">{{ getSendVcodeText }}</div>
			</div>
			<div class="nb-register-account-label-margin">验证码</div>
			<div class='nb-login-email-field-container'>
				<input class="nb-login-input-email" type="text" placeholder="请在此输入验证码" v-model="this.vcode"/>
			</div>
			<div class="nb-register-account-label-margin">密码</div>
			<div class='nb-login-email-field-container'>
				<input class="nb-login-input-email" type="password" placeholder="请在此输入密码" v-model="this.password"/>
			</div>
			<div class="nb-register-account-label-margin">确认密码</div>
			<div class='nb-login-email-field-container'>
				<input class="nb-login-input-email" type="password" placeholder="请在此确认密码" v-model="this.repassword"/>
			</div>
			<div class='nb-register-account-confirm-btn-container'>
				<div class="nb-register-account-submit" @click="onRegConfirm">提交</div>
			</div>
		</div>
		<div class="nb-login-form-container" v-if="this.currentSelectedTab === 'LOGIN'">
			<div class="nb-register-account-label">邮箱</div>
			<div class='nb-login-email-field-container'>
				<input class="nb-login-input-email" type="email" placeholder="请在此输入您的邮箱" v-model="this.loginEmail"/>
			</div>
			<div class="nb-register-account-label-margin">密码</div>
			<div class='nb-login-email-field-container'>
				<input class="nb-login-input-email" type="password" placeholder="请在此输入密码" v-model="this.loginPassword"/>
			</div>
			<div class='nb-register-account-confirm-btn-container'>
				<div class="nb-register-account-submit" @click="onLoginConfirm">确认</div>
			</div>
		</div>

	</div>
</template>

<script>
import {getClientId, hideLoading, postRequestAsync, showLoading, showToast, verifyEmail} from "@/utils/methods";

export default {
	name: "RegisterModalContent",
	props: {
		regOrLogin: {
			type: String,
			default: 'BOTH',
		}
	},
	mounted() {
		if (this.regOrLogin === 'REG') {
			this.currentSelectedTab = 'REGISTER'
		}
		if (this.regOrLogin === 'LOGIN') {
			this.currentSelectedTab = 'LOGIN'
		}
	},
	data() {
		return {
			email: '',
			password: '',
			vcode: '',
			repassword: '',
			sendVcodeCountdown: 0,
			currentSelectedTab: 'LOGIN',
			loginEmail: '',
			loginPassword: ''
		};
	},
	computed: {
		getSendVcodeText() {
			if (this.sendVcodeCountdown <= 0 || !this.sendVcodeCountdown) {
				return '发送验证码'
			}
			return `${this.sendVcodeCountdown}秒后重发`
		}
	},
	methods: {
		async onSendVcode() {
			if (this.sendVcodeCountdown && this.sendVcodeCountdown > 0) {
				return
			}

			if (!verifyEmail(this.email)) {
				showToast('请输入正确的邮箱')
				return
			}

			const clientId = getClientId()

			showLoading()
			const response = await postRequestAsync(`web/sendRegisterVcode`, {
				email: this.email,
				clientId: clientId
			})
			hideLoading()
			if (response) {
				if (response.code === 200) {
					this.sendVcodeCountdown = 60
					const interval = setInterval(() => {
						--this.sendVcodeCountdown
						if (this.sendVcodeCountdown === 0) {
							clearInterval(interval)
						}
					}, 1000)
					showToast('验证码已发出。如果没有收到邮件，请检查垃圾邮件箱。')
					return
				}
				if (response.code === 301) {
					showToast('网络错误，请重试')
					return
				}
				if (response.code === 203) {
					showToast('请输入正确的邮箱')
					return
				}
				if (response.code === 202) {
					showToast('用户已存在，请直接登录')
				}
			}
		},
		async onRegConfirm() {
			if (!verifyEmail(this.email)) {
				showToast('请输入正确的邮箱')
				return
			}

			if (!this.vcode) {
				showToast('请输入验证码')
				return
			}

			if (!this.password) {
				showToast('请输入密码')
				return
			}

			if (!this.repassword) {
				showToast('请如确认密码')
				return
			}

			if (this.password !== this.repassword) {
				showToast('两次密码输入不一致')
				return
			}
			let referCode
			if (this.$store.state.referCode) {
				referCode = this.$store.state.referCode
			}
			showLoading()
			const response = await postRequestAsync(`web/registerAccount`, {
				email: this.email,
				vcode: this.vcode,
				password: this.password,
				referCode: referCode
			})
			hideLoading()
			if (response) {
				if (response.code === 200) {
					showToast('注册成功')
					localStorage.setItem('sessionId', response.sessionInfo?.sessionId)
					localStorage.setItem('sessionExpireTimeStamp', response.sessionInfo?.sessionExpireAt)
					this.$store.dispatch('setLoginState', {
						isLogin: true,
						sessionExpireTimestamp: response.sessionInfo?.sessionExpireAt
					})
					this.$store.dispatch('setUserInfo', {email: response.email, planExpireTime: null})
					this.$emit('registerSuccess');
					return
				}
				if (response.code === 202) {
					showToast('验证码错误')
					return
				}
				if (response.code === 204) {
					showToast('推荐码不存在')
					return
				}
				if (response.code === 205) {
					showToast('邮箱已注册')
					return
				}
				showToast('网络错误，请重试')
			}
		},
		onLoginClicked() {
			this.currentSelectedTab = 'LOGIN'
		},
		onRegisterClicked() {
			this.currentSelectedTab = 'REGISTER'
		},
		async onLoginConfirm() {
			const email = this.loginEmail
			const password = this.loginPassword
			showLoading()
			const response = await postRequestAsync(`webLogin/login`, {email: email, password: password})
			hideLoading()
			if (response) {
				if (response.code === 202) {
					showToast('用户不存在')
					return
				}
				if (response.code === 203) {
					showToast('用户名密码不匹配')
					return
				}
				if (response.code === 204) {
					showToast('参数错误')
					return
				}
				if (response.code === 205) {
					showToast('服务器错误，请重试')
					return
				}

				if (response.code === 200) {
					showToast('登录成功')
					localStorage.setItem('sessionId', response.sessionId)
					localStorage.setItem('sessionExpireTimeStamp', response.sessionExpireAt)
					this.$store.dispatch('setLoginState', {
						isLogin: true,
						sessionExpireTimestamp: response.sessionExpireAt
					})
					this.$store.dispatch('setUserInfo', {
						email: response.email,
						planExpireTime: response.planExpireTime
					})

					this.$emit('loginSuccess');

				}
			}
		}
	}
}
</script>

<style scoped>
.nb-register-account-container {
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: center;
	min-width: 30em;
	color: #ff8900;
	font-size: 1em;
}


@media (max-width: 900px) {
	.nb-register-account-container {
		width: 100% !important;
		min-width: 0;
	}

}

.nb-register-account-label {
	font-size: 1em;
}

.nb-register-account-label-margin {
	margin-top: 0.6em;
}

.nb-login-email-field-container {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.nb-login-input-email {
	border-top: none;
	border-right: none;
	border-left: none;
	border-bottom: 1px solid #FF8900;
	font-size: 1em;
	background: transparent;
	margin-top: 0.2em;
	flex: 1;
	width: inherit;
	color: #ff8900;
}

.nb-login-send-vcode-btn {
	background-color: #FF8900;
	font-size: 0.8em;
	color: #3D3020;
	border-radius: 0.1em;
	padding: 0 0.4em;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	overflow: hidden; /* 隐藏溢出内容 */
	white-space: nowrap; /* 不换行 */
	text-overflow: ellipsis; /* 使用省略号表示溢出内容 */
	width: fit-content;
}

.nb-register-account-confirm-btn-container {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.nb-register-account-submit {
	background-color: #FF8900;
	color: #3D3020;
	padding: 0.2em 0.6em;
	width: fit-content;
	margin-top: 0.6em;
	font-size: 1em;
	border-radius: 0.1em;
	cursor: pointer;
}

.nb-register-account-tab-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.nb-register-form-container, .nb-login-form-container {
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: center;
	width: 100%;
	color: #ff8900;
	font-size: 1em;
}

.nb-register-account-tab {
	margin: 0 0.4em;
	cursor: pointer;
}

.active-tab {
	border-bottom: 2px solid #FF8900;
	font-weight: bold;
}
</style>