import {createRouter, createWebHistory} from 'vue-router';
import HomePage from './Pages/HomePage.vue';
import UsdtPaymentPage from './Pages/UsdtPaymentPage';
import ReferDownloadPage from './Pages/ReferDownloadPage';

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: '/',
			component: HomePage,
			name: 'HomePage'
		},
		{
			path: '/usdt',
			component: UsdtPaymentPage,
			name: 'UsdtPaymentPage'
		},
		{
			path: '/referDownload',
			component: ReferDownloadPage,
			name: 'referDownload'
		},
		{
			path: '/referRegister',
			component: ReferDownloadPage,
			name: 'referDownload'
		}
	]
});

export default router;