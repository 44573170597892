const userList = [
	{
		img: require('../images/avatars/user1.png'),
		info: '星云加速器 Android版用户',
		name: '金灵',
		comment: '一直在用星雲加速器，網絡挺穩定的，一直以為這些軟件都是一樣卡頓，星雲就是不一樣，好軟件，超信賴！也推薦給了朋友了，一直都用這個，好評！ ！ ！ '
	},
	{
		img: require('../images/avatars/user2.png'),
		info: '星云加速器 Windows版用户',
		name: 'Samuel Xu',
		comment: '作者簡直太良心了，速度比同類軟件快上不止一倍啊，用過最好最舒心的加速器，給作者一個大大的讚！'
	},
	{
		img: require('../images/avatars/user3.png'),
		info: '星云加速器 Windows版用户',
		name: '平淡幸福',
		comment: '真的是很棒了 價格能接受 速度超級快的加速器 不出意外會一直用下去的 服務器也很穩定 棒棒噠。'
	},
	{
		img: require('../images/avatars/user4.png'),
		info: '星云加速器 Macos版用户',
		name: '仙鹿',
		comment: ' 我覺得這個軟件非常的好，也不知道為什麼，我覺得他的界面十分的整潔，讓我看的一目了然，不像某些加速器軟件說的挺好，可是用起來卻不行，我覺得以後我是不會卸載這款加速器軟件了'
	},
	{
		img: require('../images/avatars/user5.png'),
		info: '星云加速器 Android版用户',
		name: 'Winters Wang',
		comment: '星云加速器这个软件非常良心，界面操作简便不繁琐一用就会了，链接速度稳定不断线，看视频打游戏都很好用，偶尔上ins找我的男神表白，太爽了。'
	},
	{
		img: require('../images/avatars/user6.png'),
		info: '星云加速器 Macos版用户',
		name: 'Jim Yang',
		comment: '终于找到了，竟然还没有流量限制，每天都离不开google和YouTube，最最最大的福利是tumblr看视频一点都不卡，老司机懂的！'
	},
	{
		img: require('../images/avatars/user7.png'),
		info: '星云加速器 Android版用户',
		name: 'HouBin Chou',
		comment: '简单好用速度快，简直神奇，连接速度极快，玩坦克世界闪电战贼稳，断了还能自动连接,这个加速器帮了大忙了。'
	},
	{
		img: require('../images/avatars/user8.png'),
		info: '星云加速器 Macos版用户',
		name: '梅蒂琪',
		comment: '雪中送炭呀，特别赞，对于我这个需要经常翻墙的人简直就是救星，看1080p的YouTube视频毫无压力，也不限速，非常好。'
	},
	{
		img: require('../images/avatars/user9.png'),
		info: '星云加速器 Windows版用户',
		name: '陆辛燃',
		comment: '相对于榜首的各大软件，价格相对而言算是便宜。下载后就像看见新大陆一样，十分好用，希望可以继续保留住这块净土和小确幸，直到阳光照到你们~'
	},
	{
		img: require('../images/avatars/user10.png'),
		info: '星云加速器 Windows版用户',
		name: 'Asuko Kochōbe',
		comment: '超级好用，连接上基本不掉线，虽然我只是用来玩游戏看看广告，不过点开广告一点开就能看了，不卡，谢谢开发者带来这么好的软件，又买了一个月时间了'
	},
	{
		img: require('../images/avatars/user11.png'),
		info: '星云加速器 Windows版用户',
		name: 'Ztx',
		comment: '超级棒的一个软件，用了几个月了很稳定价格也便宜，如果能多绑定几个设备就好了，只能绑定2个设备不够→_→，2台手机都要用还有台电脑也要使用希望能增加多个设备使用'
	},
	{
		img: require('../images/avatars/user12.png'),
		info: '星云加速器 Android版用户',
		name: 'YiLong Wang',
		comment: '真nb，速度无敌，价格也合适，真后悔没早用这个加速器'
	},
	{
		img: require('../images/avatars/user13.png'),
		info: '星云加速器 Android版用户',
		name: '神原遥',
		comment: '一直都很稳定，用了小半年没出过问题，希望能长久，比某些加速器好用的多，价格还比他们便宜，手机电脑双用，非常推荐。'
	},
	{
		img: require('../images/avatars/user14.png'),
		info: '星云加速器 Macos版用户',
		name: 'Super Man',
		comment: '用了2个多月，真的很稳定，很不错。'
	},
	{
		img: require('../images/avatars/user15.png'),
		info: '星云加速器 Android版用户',
		name: 'yz long',
		comment: '不错，油管1440p稳稳的，4k有时行有时不行。整体上性价比不错。'
	},
	{
		img: require('../images/avatars/user16.png'),
		info: '星云加速器 Macos版用户',
		name: 'YYUU AANN',
		comment: '我用过最好用的，别的都是试用速度快，充值之后就卡了，唯有星云，始终如一'
	},
	{
		img: require('../images/avatars/user17.png'),
		info: '星云加速器 Macos版用户',
		name: '杨芊芊',
		comment: '花了钱确实可以 追评，没有加速器的真的推荐试试，效果一流，在众多加速器里稳定和流畅绝对算上等的'
	},
	{
		img: require('../images/avatars/user18.png'),
		info: '星云加速器 Android版用户',
		name: 'zhemin wang',
		comment: '挺不错的，刚刚开始使用，加速效果明显，让我没想到的是有这个加速FIFA，效果居然比用游戏加速器加速效果差不多，甚至在某些方面更好，支持一下，希望游戏加速效果能更好😊'
	},
	{
		img: require('../images/avatars/user19.png'),
		info: '星云加速器 Android版用户',
		name: '今晚打老虎',
		comment: '还不错 我都按推荐来链接的 一开始没付费使用了一下感觉速度挺快就开了会员 最重要是什么 是使用国内的软件的时候不用关软件 这个就很好了 不用来回切换 看视频速度很快'
	},
	{
		img: require('../images/avatars/user20.png'),
		info: '星云加速器 Windows版用户',
		name: 'Damion_2333',
		comment: '看看推和p站這個速度完全可以～和其他的加速器比起來確實算便宜的 挺不錯的'
	}
]


export {
	userList
};

