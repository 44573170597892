<template>
	<div class="nb-user-info-container">
		<div class="nb-user-info-label">Email</div>
		<div class="nb-user-info-text">{{ this.getUserInfo.email }}</div>
		<div class="nb-user-info-label">会员等级</div>
		<div class="nb-user-info-text">{{ this.getUserInfo.planExpireTime ? 'VIP会员' : '免费会员' }}</div>
		<div class="nb-user-info-label">到期时间</div>
		<div class="nb-user-info-text">{{ this.getPlanExpireTime }}</div>

		<div class="nb-user-info-logout-btn" @click="onLogout">
			<img src="../images/logout.svg" alt="logout" class="nb-user-info-logout-btn-image">
			<div>登出</div>
		</div>
	</div>
</template>

<script>
import {formatTimestamp} from "@/utils/methods";

export default {
	name: "UserInfoContent",
	computed: {
		getUserInfo() {
			return this.$store.state.userInfo
		},
		getPlanExpireTime() {
			if (!this.$store.state.userInfo.planExpireTime) {
				return '无'
			}
			return formatTimestamp(this.$store.state.userInfo.planExpireTime)
		}
	},
	methods: {
		onLogout() {
			this.$emit('userLogout');
		}
	}
}
</script>

<style scoped>
.nb-user-info-container {
	display: flex;
	flex-direction: column;
	align-items: start;
	min-width: 20em;
}

.nb-user-info-label {
	font-size: 0.8em;
	color: #E6D5B8;
	margin-bottom: 0.5em;
}

.nb-user-info-text {
	font-size: 0.8em;
	color: #E6D5B8;
	margin-bottom: 1em;
}

.nb-user-info-logout-btn {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	color: #ff8900;
	font-size: 0.8em;
	line-height: 0.8em;
	cursor: pointer;
}

.nb-user-info-logout-btn-image {
	width: 1em;
	margin-right: 0.2em;
}
</style>