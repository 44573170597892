<template>
	<div class="nb-payment-method-container">
		<div class="nb-payment-method-row" @click="onPaymentMethodClicked('alipay')">
			<img src="../images/payment/alipay.svg" alt="alipay" class="nb-payment-method-image">
			<div class="nb-payment-method-text">支付宝</div>
			<img src="../images/payment/radio_unselected.svg" alt="radio" class="nb-payment-method-radio-image" v-if="this.currentSelectedMethod !== 'alipay'">
			<img src="../images/payment/radio_selected.svg" alt="radio" class="nb-payment-method-radio-image" v-if="this.currentSelectedMethod === 'alipay'">
		</div>
		<div class="nb-payment-method-row" @click="onPaymentMethodClicked('wechat_pay')">
			<img src="../images/payment/wechatpay.svg" alt="wechatpay" class="nb-payment-method-image">
			<div class="nb-payment-method-text">微信支付</div>
			<img src="../images/payment/radio_unselected.svg" alt="radio" class="nb-payment-method-radio-image" v-if="this.currentSelectedMethod !== 'wechat_pay'">
			<img src="../images/payment/radio_selected.svg" alt="radio" class="nb-payment-method-radio-image" v-if="this.currentSelectedMethod === 'wechat_pay'">
		</div>
		<div class="nb-payment-method-row" @click="onPaymentMethodClicked('credit_card')">
			<img src="../images/payment/credit_card.svg" alt="credit_card" class="nb-payment-method-image">
			<div class="nb-payment-method-text">信用卡支付</div>
			<img src="../images/payment/radio_unselected.svg" alt="radio" class="nb-payment-method-radio-image" v-if="this.currentSelectedMethod !== 'credit_card'">
			<img src="../images/payment/radio_selected.svg" alt="radio" class="nb-payment-method-radio-image" v-if="this.currentSelectedMethod === 'credit_card'">
		</div>
		<div class="nb-payment-method-row" @click="onPaymentMethodClicked('usdt')">
			<img src="../images/payment/USDT.svg" alt="USDT" class="nb-payment-method-image">
			<div class="nb-payment-method-text">USDT支付</div>
			<img src="../images/payment/radio_unselected.svg" alt="radio" class="nb-payment-method-radio-image" v-if="this.currentSelectedMethod !== 'usdt'">
			<img src="../images/payment/radio_selected.svg" alt="radio" class="nb-payment-method-radio-image" v-if="this.currentSelectedMethod === 'usdt'">
		</div>
		<div class="nb-payment-method-confirm-container">
			<div class="nb-payment-method-confirm-btn" @click="onConfirmClicked">确定</div>
		</div>
	</div>
</template>

<script>
import {
	getAlipayLink,
	getWechatPayLink,
	showLoading,
	hideLoading, getCreditCardPaymentLink, getUsdtTransactionId
} from "@/utils/methods";
export default {
	name: "PaymentMethodsContent",
	props: {
		selectedPlanType: String
	},
	data() {
		return {
			currentSelectedMethod: 'alipay'
		}
	},
	mounted() {
		this.currentSelectedMethod = 'alipay'
	},
	methods: {
		onPaymentMethodClicked(type) {
			this.currentSelectedMethod = type;
		},
		async onConfirmClicked() {
			this.$emit('onConfirmClicked', this.currentSelectedMethod);
			let result
			if (this.currentSelectedMethod === 'alipay') {
				showLoading()
				result = await getAlipayLink(this.selectedPlanType)
				hideLoading()
				if (result.code === 200){
					window.open(result.msg?.payUrl, '_blank');
				}
				return
			}
			if (this.currentSelectedMethod === 'wechat_pay') {

				showLoading()
				result = await getWechatPayLink(this.selectedPlanType)
				hideLoading()
				if (result.code === 200){
					window.open(result.msg?.payUrl, '_blank');
				}
				return
			}
			if (this.currentSelectedMethod === 'credit_card') {

				showLoading()
				result = await getCreditCardPaymentLink(this.selectedPlanType)
				hideLoading()
				if (result.code === 200){
					window.open(result.msg?.payUrl, '_blank');
				}
				return
			}
			if (this.currentSelectedMethod === 'usdt') {
				showLoading()
				result = await getUsdtTransactionId(this.selectedPlanType)
				hideLoading()
				if (result.code === 200){
					const newUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
					window.open(`${newUrl}/usdt?id=${result.msg.transactionId}`, '_blank');
				}
				return
			}
		}
	}
}
</script>

<style scoped>
.nb-payment-method-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.nb-payment-method-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	color: #ff8900;
	width: 100%;
	margin-bottom: 1em;
	font-size: 1em;
	cursor: pointer;
}

.nb-payment-method-image {
	width: 1em;
	margin-right: 0.4em;
}
.nb-payment-method-radio-image{
	width: 1em;
}

.nb-payment-method-text{
	flex: 1;
	text-align: start;
	font-size: 1em;
	line-height: 1em;
	min-width: 10em;
}

.nb-payment-method-confirm-btn{
	background-color: #ff8900;
	color: #1f1f35;
	padding: 0.2em 1em;
	border-radius: 0.2em;
	cursor: pointer;
	font-size: 1em;
}


</style>