import Vuex from "vuex";

export default new Vuex.Store({
	state: {
		isLogin: false,
		sessionExpireTimestamp: null,
		userInfo: {
			email: null,
			planExpireTime: null
		},
		referCode: null
	},
	mutations: {
		SET_LOGIN_STATE(state, payload) {
			state.isLogin = payload.isLogin;
			state.sessionExpireTimestamp = payload.sessionExpireTimestamp;
		},
		SET_USER_INFO(state, payload) {
			state.userInfo.email = payload.email;
			state.userInfo.planExpireTime = payload.planExpireTime;
		},
		CLEAR_USER_INFO(state) {
			state.userInfo.email = null;
			state.userInfo.planExpireTime = null;
		},
		LOGOUT_USER(state) {
			state.isLogin = false;
			state.sessionExpireTimestamp = null;
			state.userInfo.email = null;
			state.userInfo.planExpireTime = null;
		},
		SET_REFER_CODE(state, payload) {
			state.referCode = payload.referCode;
		}
	},
	actions: {
		setLoginState({ commit }, payload) {
			commit('SET_LOGIN_STATE', payload);
		},
		setUserInfo({ commit }, payload) {
			commit('SET_USER_INFO', payload);
		},
		clearUserInfo({ commit }) {
			commit('CLEAR_USER_INFO', null);
		},
		logoutUser({ commit }) {
			localStorage.removeItem('sessionId')
			localStorage.removeItem('sessionExpireTimeStamp')
			commit('LOGOUT_USER', null);
		},
		setReferCode({ commit }, payload) {
			commit('SET_REFER_CODE', payload);
		}
	}
});