import 'babel-polyfill'
import Es6Promise from 'es6-promise'
require('es6-promise').polyfill()
Es6Promise.polyfill()
import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './utils/store';
import {getServerUrl} from './utils/methods';

const app = createApp(App);

router.beforeEach(async (to, from, next) => {
	try {
		await getServerUrl()
		next()
	} catch (error) {
		console.error(error)
		next(false)
	}
})

app.use(router)
app.use(store)
app.mount('#app')
